import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { select } from '@ngrx/store';

import { selectAppLoading } from '@store/misc/loading-indicator.selectors';
import { ReactiveComponent } from '@core/components/_base/reactive.component';

@Component({
  standalone: true,
  selector: 'dsp-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent extends ReactiveComponent implements OnInit {
  @Input() set loading(loading: boolean) {
    this.$loading.set(loading ?? false);
    this.cd.markForCheck();
  }

  public $loading: WritableSignal<boolean> = signal(false);
  private cd = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.store
      .pipe(select(selectAppLoading), debounceTime(200), distinctUntilChanged())
      .subscribe(loading => {
        this.$loading.set(loading ?? false);
        this.cd.markForCheck();
      });
  }
}
